import {Component, Inject, Input, OnChanges} from '@angular/core'
import {Router} from '@angular/router'
import {WINDOW} from '../../application/app'

/**
 * Component to display the bread crumbs.
 */
@Component({
    selector: 'spb-breadcrumbs',
    templateUrl: './breadcrumbs.component.html',
    styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnChanges {

  /**
   * The path to display.
   */
  @Input() path = ''

  /**
   * An array
   */
  @Input() links: string[] = ['']

  /**
   * The list of destinations connected to the breadcrumbs.
   */
  public elements: string[] = []

  /**
   * Default route address
   */
  readonly defaultAddress = 'https://www.sparbankensyd.se'

  /**
   * Constructor
   *
   * @param router - Needed to route internally
   * @param injectedWindow - Needed to route to new location
   */
  constructor(
    private router: Router,
    @Inject(WINDOW) private injectedWindow: Window
  ) {
  }

  /**
   * Create an array of the passed in string of path. Anything
   * to be listed after 'Sparbanken Syd | ' e.g 'Låna | Lånelöfte
   * spaces are significant.
   */
  public ngOnChanges() {
    this.elements = this.path.split('|')
  }

  /**
   * A navigation wrapper to be able to navigate. Navigates externally
   * if the link contains 'https://'
   *
   * @param position - Position in the array of links to route to.
   */
  public navigate(position: number): void {
    if (position === -1) {
      this.injectedWindow.location.href = this.defaultAddress
      return
    }
    if (this.links[position].indexOf('https://') !== -1) {
      this.injectedWindow.location.href = this.links[position]
    } else {
      this.router.navigate([this.links[position]]).then()
    }
  }
}
