<div class="holder">
  <div class="breadcrumbs">
    <span
      (click)="navigate(-1)"
      (keyup.enter)="navigate(-1)"
      tabindex="0"
      role="button"
      class="link">
      Sparbanken Syd<span class="thin"> |  </span>
    </span>
    @for (link of elements; track $index) {
      @if (!$first) {
        <span class="last"> /  </span>
      }
      @if (!$last) {
        <span
          (click)="navigate($index)"
          (keyup.enter)="navigate($index)"
          tabindex="0"
          role="button"
          class="link">
          {{ link }}
        </span>
      }
      @if ($last) {
        <span class="last">{{ link }}</span>
      }
    }
  </div>
</div>