import {InjectionToken} from '@angular/core'

/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const WINDOW = new InjectionToken<Window>('window')
/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
export const LOCAL_STORAGE = new InjectionToken<Storage>('localStorage')