export const environment = {
  production: false,
  kycServiceUrl: 'https://kyc-test.kundkannedom.sparbankensyd.se/api',
  authServiceUrl: 'https://kyc-test.kundkannedom.sparbankensyd.se/service',
  debug: true,
  showStartBankid: false,
  domain: 'kyc.internal.sparbankensyd.se',
  mock: true,
  testData: {
    guardians: [{
      name: 'Pecka Lindmark',
      personNummer: '19101010-1010',
      email: 'test@test.com'
    }, {
      name: 'Thomas Ravelli',
      personNummer: '19860101-6820',
      email: 'test2@test.com'
    }
    ]
  }
}
