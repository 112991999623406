import {Injectable} from '@angular/core'
import { HttpClient } from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {BaseService} from './base.service'
import {Finalize} from './finalize.interface'
import {ConfigService} from './config.service'

@Injectable({
  providedIn: 'root'
})
export class KycService extends BaseService implements Finalize {

  public admin = false

  public completeData = {
    title: 'Tack för din hjälp!',
    text: 'Stort tack för att du håller informationen uppdaterad. Du kan nu stänga detta webbläsarfönster.',
    close: false,
  }

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
  ) {
    super(configService.bankIdResult)
  }

  /**
   * Once signature is received we finalize the KYC.
   */
  public finalizeGuardian(id: string, orderRef: string, guardianRef: string): Observable<any> {
    const url = `${environment.kycServiceUrl}/guardian/finalize`
    const data = {id, orderRef, guardianRef}
    return this.httpClient.put<any>(url, data, this.httpOptions)
  }

  public getKycForGuardian(): Observable<any> {
    const url = `${environment.kycServiceUrl}/guardian`
    return this.httpClient.put<any>(url, {}, this.httpOptions)
  }

  public submitMinor(payload: any): Observable<any> {
    const url = `${environment.kycServiceUrl}/minor/submit`
    return this.httpClient.put<any>(url, payload)
  }
}
