import {Inject, Injectable} from '@angular/core'
import {LOCAL_STORAGE} from '../application/app'
import {BaseService} from './base.service'
import {BehaviorSubject, Observable, of} from 'rxjs'
import {environment} from '../../environments/environment'
import { HttpClient } from '@angular/common/http'
import {first, map, switchMap} from 'rxjs/operators'
import {ConfigService} from './config.service'
import {Finalize} from './finalize.interface'
import {Router} from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class AdminService extends BaseService implements Finalize {

  admin = true

  public completeData = {
    title: 'Tack!',
    text: 'Din kund har nu signerat sin kundkännedom och en rapport har skickats till din e-post.',
    close: true
  }

  public adminData$ = new BehaviorSubject<any>(null)

  private adminData: any

  private storageName = 'kyc-admin-data'

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService,
    private router: Router,
    @Inject(LOCAL_STORAGE) private injectedLocalStorage: Storage
  ) {
    super(configService.bankIdResult)
    this.getAdminData()
  }

  public getKycForAdmin(kycId: string, adminData): Observable<any> {
    const url = `${environment.kycServiceUrl}/guardian/admin`
    return this.httpClient.put<any>(url, {id: kycId, adminData}, this.httpOptions)
  }

  /**
   * Lets a admin finalize a kyc after sign is done
   *
   * @param id
   * @param orderRef
   * @param personNummer
   */
  public finalizeMinor(id: string, orderRef: string, personNummer: string): Observable<any> {
    let adminData = {}
    // need to get the admin data to authorize in BE
    return this.adminData$.pipe(
      first(),
      switchMap((res) => {
        adminData = res
        const url = `${environment.kycServiceUrl}/admin/finalize`
        const data = {id, orderRef, personNummer, adminData}
        return this.httpClient.put<any>(url, data, this.httpOptions)
      }))
  }

  /**
   * Once signature is received we finalize the KYC.
   */
  public finalize(orderRef: string): Observable<any> {
    const url = `${environment.kycServiceUrl}/finalize/${orderRef}`
    return this.httpClient.get<any>(url, this.httpOptions)
  }

  public submit(report: any): Observable<any> {
    report.adminData = this.adminData
    const url = `${environment.kycServiceUrl}/submit`
    return this.httpClient.put<any>(url, report)
  }

  public submitMinor(payload: any): Observable<any> {
    payload.adminData = this.adminData
    const url = `${environment.kycServiceUrl}/minor/submit`
    return this.httpClient.put<any>(url, payload)
  }

  public fetchAdminData(): Observable<void> {
    const url = `${environment.kycServiceUrl}/admin`
    return this.adminData$.pipe(
      first(),
      switchMap((adminData: any) => {
        if (!adminData && this.ready) {
          return this.httpClient.get<any>(url, this.httpOptions)
        }
        return of(adminData)
      }),
      map((res: any) => {
        this.adminData$.next(res)
        this.setAdminData(res)
      })
    )
  }

  public getAdminData(): void {
    const stored = this.injectedLocalStorage.getItem('kyc-admin-data')
    try {
      this.adminData = JSON.parse(stored as string)
      if ((this.adminData.iat + (60 * 60 * 8 * 1000)) < new Date().getTime()) {
        this.injectedLocalStorage.removeItem(this.storageName)
        this.adminData = undefined
      }
    } catch {
      // nevermind
    }
    this.adminData$.next(this.adminData)
  }

  public reset(): void {
    this.adminData = null
    this.injectedLocalStorage.removeItem(this.storageName)
    this.ready = false
    this.configService.bankIdResult.next({accessToken: ''})
    this.adminData$.next(this.adminData)
    this.router.navigate(['kyc', 'login']).then()
  }

  private setAdminData(data: any): void {
    this.adminData = data
    this.injectedLocalStorage.setItem(this.storageName, JSON.stringify(data))
  }
}
